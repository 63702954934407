<template>
    <div class="schedule-content">
        <table>
             <thead>
                <tr>
                    <th></th>
                    <th>CANOPY & BOIS DES GAULOIS</th>
<!--                    <th>TYRO' sur LOIRE *</th>-->
                    <th>WOODSNACK</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="i in 12" :key="i">
                    <td>{{ months[i] }}</td>
                    <td v-if="false">toto</td>
                    <td v-if="typeof(getContent(i, 'canopy').value) != 'undefined'" :rowspan="getContent(i,'canopy').rowspan" :class="getContent(i,'canopy').cssClass" v-html="getContent(i, 'canopy').value"></td>
<!--                    <td v-if="typeof(getContent(i, 'tyro').value) != 'undefined'" :rowspan="getContent(i,'tyro').rowspan" :class="getContent(i,'tyro').cssClass" v-html="getContent(i, 'tyro').value"></td>-->
                    <td v-if="typeof(getContent(i, 'woodsnack').value) != 'undefined'" :rowspan="getContent(i,'woodsnack').rowspan" :class="getContent(i,'woodsnack').cssClass" v-html="getContent(i, 'woodsnack').value"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "SchedulerView",
        props: ['planninglist'],
        data() {
            return {
                i: 1,
                months: {
                    1: 'Janvier',
                    2: 'Février',
                    3: 'Mars',
                    4: 'Avril',
                    5: 'Mai',
                    6: 'Juin',
                    7: 'Juillet',
                    8: 'Aout',
                    9: 'Septembre',
                    10: 'Octobre',
                    11: 'Novembre',
                    12: 'Décembre',
                },
                htmlRender: `<td style="color: red">toto</td>`,
                message: '<span style="color:blue!important;">WORLD</span>'
            }
        },
        mounted() {
            // console.log(this.planninglist);
        },
        methods: {
            getContent(month, category) {
                var content = this.planninglist.filter(planning => planning.start == month && planning.category == category)[0];
                var td = [];

                if(content != null && content.active) {
                    var rowspan = content.end - content.start + 1;
                    var cssClass = category == 'canopy' ? 'bg-green' : category == 'tyro' ? 'bg-blue' : 'bg-yellow';
                    if(content.disabled) {
                        cssClass = cssClass + ' close';
                    }

                    td.value = content.value;
                    td.cssClass = cssClass;
                    td.rowspan = rowspan;
                }

                return td;
            }
        },
    }
</script>

<style scoped>
    .schedule-content {
        max-width: 100%;
        overflow: auto;
    }
    table{
        width: 100%;
        text-align: center;
    }
    .bg-green {
        background-color: #8BCDA1;
    }
    .bg-blue {
        background-color: #319FB9;
    }
    .bg-yellow {
        background-color: #f9b740;
    }
    .close {
        opacity: 0.5;
    }
</style>
