<template>
    <div class="opinion">
        <img class="scotch" :src="scotch_image" alt="Scotch pour coller l'avis sur le bois">
        <div class="opinion-header">
            <span>{{ opinion.firstname }}</span>
            <div>
                <img v-for="n in parseInt(opinion.note)" :key="n" :src="star_image" alt="Etoile pour la notation de cet avis">
            </div>
        </div>
        <p>
            {{ opinion.comment }}
        </p>
    </div>
</template>

<script>
export default {
    name: "OpinionComponent",
    props: ['scotch_image', 'star_image', 'opinion'],
}
</script>

<style scoped>

</style>