<template>
    <div class="parcours active">
        <div class="parcours-header">
            <img :src="'/images/' + course.image " alt="Image de présentation du parcours" 
                onerror="this.onerror=null;
                    this.src='/images/parcours-defaut.webp';">
        </div>
        <h3>{{ course.name }}</h3>
        <div class="parcours-caracteristics" v-if="course.caracteristics.length > 0">
            <div class="parcours-caract" v-for="(caract, index) in course.caracteristics" :key="caract.id">
                <img :src="'/images/logos/' + caract.logo" alt="Logo de la caractéristique de ce parcours">
                <span>{{ caract.name }}</span>
            </div>
        </div>
        <p>
            {{ course.description }}
        </p>
    </div>
</template>

<script>
export default {
    name: "CourseComponent",
    props: ['course', 'base_url'],
}
</script>

<style scoped>

</style>