<template>
     <div class="form-group">
        <div style="display:flex;">
            <img :src="captcha" alt="Imga du captcha">
            <button type="button" @click="getcaptcha">Recharger</button>
        </div>

        <input type="text" id="Captcha" name="captcha" placeholder="Captcha" required>
        <input type="hidden" id="captcha_key" name="key" :value="key">
    </div>
</template>
<script>
    import axios from 'axios'
     export default {
        name: 'captchaComponent',
        data() {
            return {
                captcha: this.getcaptcha(),
                key: null,
            }
        },

        methods: {
            getcaptcha() {
                axios
                    .get('/ajax_regen_captcha')
                    .then(response => {
                        this.captcha = response.data;
                        this.key = response.data.split('?')[1];
                    });
            }
        }
    }
</script>
<style scoped lang="scss">
    .form-group {
        > div {
            margin: 1em 0;
            button {
                margin: 0 0.5em;
            }
        }
    }
</style>
