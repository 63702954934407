<template>
    <div>
        <course-list-component
            :courses="courseList"
            :base_url="base_url"
        ></course-list-component>
        <div class="parcours-carousel-buttons" v-if="getNbrPage > 1">
            <button type="button" aria-label="Precedent" @click="onChange(-1)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                </svg>
            </button>
            <button type="button" aria-label="Suivant" @click="onChange(1)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import CourseListComponent from './CourseListComponent.vue';
export default {
    name: "CourseListView",
    components: {
        "course-list-component": CourseListComponent
    },
    props: ['courses', 'base_url'],
    data() {

        return {
            "page": 1,
            "quantity": 1
        }
    },
    mounted(){

    },
    computed: {
        courseList(){
            if(this.courses.length > 1){
                //Affichage 1 par 1
                if(this.getScreenSize() < 768){
                    this.quantity = 1;
                }
                if(this.getScreenSize() > 767){
                    //Affichage 2 par 2
                    this.quantity = 2;
                }
                if(this.getScreenSize() > 1023){
                    //Affichage 3 par 3
                    this.quantity = 3;
                }

                return this.getList(this.quantity);
            }

            return this.courses;
        },
        getNbrPage(){

            return Math.ceil(this.courses.length / this.quantity);
        }
    },
    methods: {
        getScreenSize(){

            return window.innerWidth;
        },
        onChange(value){
            if(value == -1 && this.page == 1){
                this.page = this.getNbrPage;
            }else if(this.page == this.getNbrPage && value == 1){
                this.page = 1;
            }else{
                this.page = this.page + value;
            }
        },
        getList(quantity){
            var index = this.page * quantity;
            var data = [];

            for(var i = quantity; i > 0; i--){
                if(this.courses[index - i]){
                    data.push(this.courses[index - i]);
                }
            }

            return data;
        }
    },
}
</script>

<style scoped>
    button{
        color: #fff;
        font-size: 0.1em;
        width: 1.5rem;
        height: 1.5rem;
        background: #000;
        display: block;
        border-radius: 50%;
        border: none;
        margin: 1em;
        font-size: 1em;
        cursor: pointer;
    }
</style>